.positive {
  color: green;
}

.negative {
  color: red;
}

.transactions-list {
  table-layout: auto;
}

.title {
  flex-grow: 1;
}

.MuiAppBar-positionFixed.bottomAppBar {
  position: fixed;
  top: auto;
  bottom: 0;

  .addTransactionButton {
    position: absolute;
    z-index: 1;
    top: -30px;
    right: 30px;
    margin: 0 auto;
  }
}

.mainContent {
  padding-top: 64px;
  padding-bottom: 64px;
}

.clearedCheck {
  margin-top: 10px;
}

tr.MuiTableRow-root.pending {
  background-color: #FFFFE0;
}

.hide {
  visibility: hidden;
}

span.rfipbtn__del {
  display: none;
}

span.rfipbtn__icon {
  width: auto;
  border: none !important;
}

.rfipdropdown {
  right: 0 !important;
  left: auto !important;
}

button.view-older {
  width: 100%;
  border-radius: 0px;
  background-color: #ccc;
}

.MuiChip-root svg.svg-inline--fa {
  margin-left: 10px;
}